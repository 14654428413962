import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { formatYmd } from '@/utility';
import { News } from 'wklr-backend-sdk/models';
@Component
export default class NewsList extends Vue {
  formatYmd = formatYmd;

  @Prop()
  news!: News[];

  isNew(news: News): boolean {
    const sevenDays = 1000 * 60 * 60 * 24 * 7;
    const sevenDaysAgo = new Date(new Date().getTime() - sevenDays);
    return new Date(news.publishedOn) > sevenDaysAgo;
  }
}
