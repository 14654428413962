<template>
  <v-card class="public-comments-list-item" elevation="0">
    <div class="wrapper">
      <div class="detail">
        <p class="titleText">
          <a v-if="commentsAndResponsesUriExternal" :href="commentsAndResponsesUriExternal" target="_blank" class="link"
            >{{ title }}<sup><v-icon x-small color="primary">mdi-open-in-new</v-icon></sup></a
          >
          <span v-else class="nonLink">{{ title }}</span>
        </p>
        <div class="meta">
          <p class="date">
            {{ formatYmd(publicComment.publishedOn) }}
          </p>
          <p class="publisher">
            {{ publicComment.agency }}
          </p>
        </div>
        <div class="link">
          <div v-if="isLink" class="wrapper">
            結果の公示
            <a :href="linkHref" target="_blank" class="text"
              >{{ linkLabel }}<sup><v-icon x-small color="primary">mdi-open-in-new</v-icon></sup></a
            >
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { formatYmd } from '@/utility';
import { DocumentSnippets } from 'wklr-backend-sdk/models';

@Component
export default class CardPublicComment extends Vue {
  formatYmd = formatYmd;

  @Prop() publicComment!: DocumentSnippets['publicComments'][number];

  get title(): string {
    return this.publicComment.publishedTitle;
  }

  get commentsAndResponsesUriExternal(): string | null {
    return this.publicComment.commentsAndResponsesUriExternal;
  }

  get isLink(): boolean {
    return !!this.publicComment.uriOnEGov || !!this.publicComment.uriByAgency;
  }

  get linkLabel(): string {
    if (this.publicComment.uriOnEGov) return 'e-Gov';
    if (this.publicComment.uriByAgency) return '所管省庁サイト';
    return '';
  }

  get linkHref(): string {
    return this.publicComment.uriOnEGov || this.publicComment.uriByAgency || '';
  }
}
</script>

<style lang="scss" scoped>
.public-comments-list-item {
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  padding: 25px 0;

  > .wrapper {
    display: flex;
    margin: 0;

    > .detail {
      width: 100%;

      > .titleText {
        margin: 0 0 8px;

        > .link {
          text-decoration: none;
          font-weight: bold;
          font-size: 16px;
          &:hover {
            text-decoration: underline;
          }
        }

        > .nonLink {
          font-weight: bold;
          font-size: 16px;
        }
      }

      > .meta {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 8px;

        > .date {
          font-size: 13px;
          color: #707070;
          margin: 0 20px 0 0;
        }

        > .publisher {
          font-size: 13px;
          color: #707070;
          margin: 0 20px 0 0;
        }
      }

      > .link {
        line-height: 1.8;
        font-size: 13px;
        width: 100%;
        margin-bottom: 0;
        color: #707070;

        > .wrapper > .text {
          text-decoration: none;
          margin-left: 5px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
