<template>
  <v-alert dense text type="info" icon="mdi-flask-empty">
    「分野別ニュース」の関連文献機能は現在ベータ版です。専門家による監修を受けながら項目やコンテンツ等を順次アップデートしております。
  </v-alert>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
@Component({})
export default class DirectoryBetaAlert extends Vue {}
</script>
