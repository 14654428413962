<template>
  <layout-normal>
    <template #main>
      <breadcrumbs v-if="breadcrumbItems" :items="breadcrumbItems" class="top-breadcrumbs" />
      <h1 class="mb-4 practice-area-header">{{ newsCategory.name }} のニュース</h1>
      <v-card class="card-scrollable -h300 mb-10">
        <news-list :news="news" />
      </v-card>
      <div v-if="tags && tags.length > 0">
        <h1 class="mb-4 practice-area-header">{{ practiceArea.name }} 関連文献</h1>
        <directory-beta-alert />
        <v-card>
          <v-tabs center-active :show-arrows="true" class="tag-tabs">
            <v-tab
              v-for="tag in tags"
              :key="tag.id"
              class="tag-tab"
              active-class="-active"
              @change="tagSelectHandler(tag)"
              >{{ tag.name }}</v-tab
            >
          </v-tabs>
          <document-selector-toolbar
            :document-type="documentType"
            :is-law-pane-open="isLawPaneOpen"
            :is-laws-available="laws.length > 0"
            @change-document-type="documentTypeChangeHandler"
            @law-pane-toggle="lawPaneToggleHandler"
          />
          <v-container class="documents-container pa-0 mb-10">
            <v-row class="row ma-0">
              <v-col class="col ma-0 pa-0" :cols="isLawPaneOpen ? 9 : 12">
                <section v-if="documentType === 'books'" class="books">
                  <div class="card">
                    <div class="documents">
                      <p v-if="books === undefined" class="pa-8">書籍の取得に失敗しました。再読み込みしてください</p>
                      <p v-else-if="books.length < 1" class="pa-8">書籍がありません</p>
                      <template v-else>
                        <v-card v-for="document in books" :key="document.book.id" class="entry" elevation="0">
                          <document-list-item
                            :document="document.book"
                            :is-book-snippet-accessible="document.docAccessible"
                            :disabled="true"
                            variant="directory"
                            :disable-document-link="disableDocumentLink"
                          />
                        </v-card>
                      </template>
                    </div>
                  </div>
                </section>
                <section v-if="documentType === 'publicComments'">
                  <div class="card-scrollable -h500">
                    <div class="public-comments">
                      <p v-if="publicComments.length < 1" class="pa-8">パブリックコメントがありません</p>
                      <template v-else>
                        <card-public-comment
                          v-for="publicComment in publicComments"
                          :key="publicComment.publicCommentId"
                          :public-comment="publicComment"
                        />
                      </template>
                    </div>
                  </div>
                </section>
                <section v-if="documentType === 'guidelines'">
                  <div class="card-scrollable -h500">
                    <div class="guidelines">
                      <p v-if="guidelines.length < 1" class="pa-8">ガイドラインがありません</p>
                      <v-card
                        v-for="document in guidelines"
                        :key="document.guideline.id"
                        class="guidelines-list-item"
                        elevation="0"
                      >
                        <div class="wrapper">
                          <div class="detail">
                            <p class="titleText">
                              <nuxt-link
                                v-if="document.available"
                                :to="`/document/${document.guideline.guidelineId}`"
                                class="link"
                                >{{ document.guideline.title }}</nuxt-link
                              >
                              <span v-else>{{ document.guideline.title }}</span>
                            </p>
                            <div class="meta">
                              <p class="date">
                                {{ formatYmd(document.guideline.publishedOn) }}
                              </p>
                              <p class="publisher">
                                {{ document.guideline.agency }}
                              </p>
                              <a
                                v-if="document.guideline.guidelineUriExternal"
                                :href="document.guideline.guidelineUriExternal"
                                target="_blank"
                                class="externalLink"
                                >発行元で開く<sup><v-icon x-small color="primary">mdi-open-in-new</v-icon></sup></a
                              >
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </section>
              </v-col>
              <v-col v-if="isLawPaneOpen" class="ma-0 pa-0" cols="3">
                <div class="card-scrollable -h500 -law">
                  <section class="laws-container">
                    <ul class="laws-list">
                      <li v-for="law in laws" :key="law.lawId" class="item">
                        <nuxt-link :to="`/document/${law.lawId}`" class="link">
                          {{ law.title }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </section>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </template>
  </layout-normal>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import { MetaInfo } from 'vue-meta';
import NewsList from '@/components/news-list.vue';
import Breadcrumbs from '@/components/breadcrumbs.vue';
import { BreadcrumbsItem } from '@/components/breadcrumbs';
import DocumentListItem from '@/components/document-list-item.vue';
import DocumentSelectorToolbar from '@/components/document-selector-toolbar.vue';
import LayoutNormal from '@/components/base/layout-normal.vue';
import CardPublicComment from '@/components/practiceArea/card-public-comment.vue';
import { generateSearchRoute, SearchLocation } from '@/utils/routeUtils';
import { formatYmd } from '@/utility';
import * as Constants from '@/constants';
import DirectoryBetaAlert from '@/components/alert/directory-beta-alert.vue';
import {
  DocumentSnippets,
  LawSnippet,
  PracticeAreaGroupSnippet,
  PracticeArea,
  NewsCategory,
  News,
  Tag,
} from 'wklr-backend-sdk/models';
import { PartialSearchQuery } from '@/types/SearchQuery';
import NO_IMAGE from '@/assets/noimage.png';

export type DocType = 'books' | 'publicComments' | 'guidelines';

type AsyncData = Pick<
  PracticeAreaPage,
  | 'group'
  | 'practiceArea'
  | 'breadcrumbItems'
  | 'news'
  | 'newsCategory'
  | 'tags'
  | 'selectedTag'
  | 'selectedTagDocuments'
  | 'tagDocumentSnippetsMap'
  | 'isLawPaneOpen'
>;

const setBraedcrumbs = (practiceAreaGroup: PracticeAreaGroupSnippet, practiceArea: PracticeArea) => {
  const items = [];

  items.push({
    text: '分野一覧',
    exact: true,
    to: '/directories',
  });

  if (practiceAreaGroup) {
    items.push({
      text: practiceAreaGroup.name,
    });
  }

  if (practiceArea) {
    items.push({
      text: practiceArea.name,
    });
  }

  return items;
};

@Component({
  components: {
    Breadcrumbs,
    CardPublicComment,
    DocumentListItem,
    LayoutNormal,
    NewsList,
    DocumentSelectorToolbar,
    DirectoryBetaAlert,
  },
})
export default class PracticeAreaPage extends Vue {
  formatYmd = formatYmd;

  group!: PracticeAreaGroupSnippet;
  practiceArea!: PracticeArea;
  breadcrumbItems: BreadcrumbsItem[] = [];

  news: News[] = [];
  newsCategory!: NewsCategory;
  tags: Tag[] = [];
  selectedTag: Tag | undefined = undefined;
  selectedTagDocuments: DocumentSnippets | undefined = undefined;
  tagDocumentSnippetsMap: { [tagId: string]: DocumentSnippets } | undefined = undefined;
  documentType: DocType = 'books';
  isLawPaneOpen = true;

  head(): MetaInfo {
    return {
      title: this.practiceArea ? this.practiceArea.name : '',
    };
  }

  async asyncData({ app: { $repositories, $auth }, route, error }: Context): Promise<AsyncData | void> {
    if (!$auth.permissions.practiceArea) {
      error({ statusCode: 403, message: 'このページは閲覧できません' });
      return;
    }

    const practiceAreaId = route.params.practiceArea;
    const snippets = await $repositories.practiceAreas.getSnippets(practiceAreaId);

    if (snippets.practiceAreaGroup === undefined) {
      return error({ statusCode: 500, message: '分野グループが見つかりませんでした。' });
    }

    const group = snippets.practiceAreaGroup;

    const practiceArea = snippets.practiceArea;
    if (practiceArea === undefined) {
      return error({ statusCode: 500, message: '分野が見つかりませんでした。' });
    }
    const breadcrumbItems = setBraedcrumbs(group, practiceArea);

    const _tags = snippets.tags;
    const _idx = _tags.findIndex((tag) => tag.name.endsWith('一般'));
    if (_idx > 0) {
      const generalTag = _tags.splice(_idx, 1)[0];
      _tags.unshift(generalTag);
    }
    const tags = _tags;

    const news = snippets.news.news;
    const newsCategory = snippets.news.category;
    if (newsCategory === undefined) throw new Error('ニュースカテゴリーが存在しません');

    console.log(snippets);

    const tagDocumentSnippetsMap: { [tagId: string]: DocumentSnippets } = Object.fromEntries(
      snippets.documents.map((o) => [o.tag.id, o.documents]),
    );

    const selectedTag = tags[0];

    const selectedTagDocuments = selectedTag ? tagDocumentSnippetsMap[selectedTag.id] : undefined;

    // selectedTag の法令が1個もない場合は法令ペインを閉じておく
    const isLawPaneOpen = selectedTagDocuments ? selectedTagDocuments.laws.length > 0 : false;

    return {
      group,
      practiceArea,
      breadcrumbItems,
      news: news || [],
      newsCategory,
      tags,
      selectedTag,
      selectedTagDocuments,
      tagDocumentSnippetsMap,
      isLawPaneOpen,
    };
  }

  tagSelectHandler(tag: Tag): void {
    this.selectedTag = tag;
    if (this.tagDocumentSnippetsMap) this.selectedTagDocuments = this.tagDocumentSnippetsMap[this.selectedTag.id];
    this.isLawPaneOpen = this.laws.length > 0;
  }

  get tagLink(): SearchLocation | undefined {
    if (this.selectedTag) {
      const query: PartialSearchQuery = {
        tagId: [this.selectedTag.id],
      };
      return generateSearchRoute(query, Constants.Search.option);
    }
  }

  get books(): DocumentSnippets['books'] {
    return this.selectedTagDocuments ? this.selectedTagDocuments.books : [];
  }

  get laws(): LawSnippet[] {
    return this.selectedTagDocuments ? this.selectedTagDocuments.laws : [];
  }

  get publicComments(): DocumentSnippets['publicComments'] {
    return this.selectedTagDocuments ? this.selectedTagDocuments.publicComments : [];
  }

  get guidelines(): DocumentSnippets['guidelines'] {
    return this.selectedTagDocuments ? this.selectedTagDocuments.guidelines : [];
  }

  get disableDocumentLink(): boolean {
    return this.$domain.isMHMMypage;
  }

  documentTypeChangeHandler(type: DocType): void {
    this.documentType = type;
  }

  lawPaneToggleHandler(): void {
    this.isLawPaneOpen = !this.isLawPaneOpen;
  }

  readonly noImage = NO_IMAGE;
}
</script>
<style lang="scss" scoped>
.top-breadcrumbs {
  padding-left: 0;
}
.card-scrollable {
  border-radius: 0 0 4px 4px !important;
  overflow-y: scroll;

  &.-h300 {
    height: 300px;
  }
  &.-h500 {
    height: 500px;
  }

  &.-law {
    border-left: solid #e0e0e0 1px;
  }

  > .public-comments {
    margin: 0 24px;
  }

  > .guidelines {
    margin: 0 24px;

    > .guidelines-list-item {
      border-bottom: 1px solid #e0e0e0;
      border-radius: 0;
      padding: 25px 0;

      > .wrapper {
        display: flex;
        margin: 0;

        > .detail {
          width: 100%;

          > .titleText {
            margin: 0 0 8px;

            > .link {
              text-decoration: none;
              font-weight: bold;
              font-size: 16px;
              &:hover {
                text-decoration: underline;
              }
            }

            > .nonLink {
              font-weight: bold;
              font-size: 16px;
            }
          }

          > .meta {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 8px;

            > .date {
              font-size: 13px;
              color: #707070;
              margin: 0 20px 0 0;
            }

            > .publisher {
              font-size: 13px;
              color: #707070;
              margin: 0 20px 0 0;
            }

            > .externalLink {
              margin: 0 20px 0 0;
              font-size: 13px;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.documents-container > .row > .col > .books {
  > .card {
    border-radius: 0 0 4px 4px !important;
    overflow-y: scroll;
    height: 500px;
  }

  > .card > .documents {
    margin: 0 24px;

    > .entry {
      border-bottom: 1px solid #e0e0e0;
      border-radius: 0;
      padding: 18px 8px 25px;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  > .more-link {
    text-align: center;
    border-radius: 0;
    padding: 16px 0;
    border-top: solid #e0e0e0 1px;

    > .text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.practice-area-header {
  font-size: 28px;
  color: #212121;
}

.tag-tabs {
  border-bottom: solid #e0e0e0 1px;
}

.tag-tab {
  font-size: 16px;
  color: #707070;
  letter-spacing: 0;
  min-width: 0;
  &.-active {
    color: #1976d2;
    font-weight: bold;
  }
}

.laws-container {
  padding: 16px;

  > .heading {
    font-size: 16px;
    font-weight: bold;
    padding-top: 0;
  }
  > .separator {
    border: 0;
    border-top: 1px solid #e0e0e0;
  }
}

.laws-list {
  padding: 6px 0 0 8px;

  > .item {
    font-size: 14px;
    list-style: none;
    line-height: 1.2;
    padding: 7px 0;
    list-style-type: '・ ';
    list-style-position: inside;

    > .link {
      font-size: 14px;
      color: #212121;
      text-decoration: none;
      line-height: 1.3;

      &:hover {
        color: #1976d2;
      }
    }
  }
}
</style>
