<template>
  <div class="document-selector-toolbar">
    <v-btn-toggle
      group
      dense
      mandatory
      class="documentType-selector"
      :value="documentTypeIndex"
      @change="documentTypeChangeHandler"
    >
      <v-btn text elevation="0" class="item" active-class="-selected">書籍</v-btn>
      <v-btn text elevation="0" class="item" active-class="-selected">パブコメ</v-btn>
      <v-btn text elevation="0" class="item" active-class="-selected">ガイドライン</v-btn>
    </v-btn-toggle>
    <v-btn
      text
      elevation="0"
      class="toggle-button"
      :class="{ '-selected': isLawPaneOpen }"
      :disabled="!isLawsAvailable"
      @click="lawPaneToggleHandler"
      ><v-icon>mdi-file-document</v-icon>関連する法令</v-btn
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { DocType } from '@/pages/directories/_practiceAreaGroup/_practiceArea/index.vue';

@Component
export default class DocumentSelectorToolbar extends Vue {
  @Prop()
  documentType!: DocType;

  @Prop()
  isLawPaneOpen!: boolean;

  @Prop()
  isLawsAvailable!: boolean;

  get documentTypeIndex(): number {
    switch (this.documentType) {
      case 'books':
        return 0;
      case 'publicComments':
        return 1;
      case 'guidelines':
        return 2;
    }
  }

  lawPaneToggleHandler() {
    this.$emit('law-pane-toggle');
  }

  documentTypeChangeHandler(index: number | undefined) {
    if (index === undefined) return;
    if (index === 0) {
      this.$emit('change-document-type', 'books');
    }
    if (index === 1) {
      this.$emit('change-document-type', 'publicComments');
    }
    if (index === 2) {
      this.$emit('change-document-type', 'guidelines');
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-button {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;

  &.-selected {
    color: $primary;
  }
}

.document-selector-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 0 !important;
  border-bottom: solid #e0e0e0 1px;
}

.documentType-selector {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54);

  > .item {
    display: inline;
    border-radius: 28px !important;
    padding: 0 13px;
    height: 32px;
    margin: 4px 2px;

    &.v-btn {
      // v-btn と同じスタイルを適用する
      padding: 0 8px;
      margin: 4px;
    }

    &.-selected {
      font-weight: bold;
      color: $primary;
      border: thin solid;
      &:before {
        background: transparent;
      }
    }

    &:hover {
      background-color: #fafafa;
    }
  }
}
</style>
